<template>
  <div class="container" style="">
    <div style="display:flex;">
      <h5 style="margin:auto;">Settings</h5>
    </div>
    <hr>
    <div id="settingsContainer">
      <div>
        <ul class="settings">
          <li class="familyPreference clickable" v-if="this.isAdmin === '1'" @click="this.$root.gotoRoute({ path: '/settings/family'})"><div class="actionIcon"><font-awesome-icon :icon="['fas', 'users']" /></div><div>Manage Family Members and Bank Accounts</div></li>
          <li class="familyProfile clickable" v-if="this.isAdmin === '1'" @click="this.$root.gotoRoute({ path: '/settings/familyprofile'})"><div class="actionIcon"><font-awesome-icon :icon="['fas', 'cog']" /></div><div>Family Settings</div></li>
          <li class="myAccount clickable" @click="gotoMyProfile"><div class="actionIcon"><font-awesome-icon :icon="['fas', 'user']" /></div><div>My Profile</div></li>
          <li class="myBankAccounts clickable" v-if="this.isAdmin === '1'" @click="this.$root.gotoRoute({ path: '/settings/bas'})"><div class="actionIcon"><font-awesome-icon :icon="['fas', 'university']" /></div><div>My Bank Accounts</div></li>
          <li class="changePassword clickable" @click="this.$root.gotoRoute({ path: '/settings/passwordchange'})"><div class="actionIcon"><font-awesome-icon :icon="['fas', 'key']" /></div><div>Change Password</div></li>
          <li class="about clickable" @click="this.$root.gotoRoute({ path: '/settings/faqs'})"><div class="actionIcon"><font-awesome-icon :icon="['fas', 'question']" /></div><div>FAQs and Support</div></li>
          <li class="about clickable" @click="this.$root.gotoRoute({ path: '/settings/contactus'})"><div class="actionIcon"><font-awesome-icon :icon="['fas', 'paper-plane']" /></div><div>Contact Us</div></li>
          <li class="invite clickable" v-if="this.isAdmin === '1'" @click="this.$root.gotoRoute({ path: '/settings/invite'})"><div class="actionIcon"><font-awesome-icon :icon="['fas', 'bullhorn']" /></div><div>Share Goblin Bank with Other Families</div></li>
          <li class="about clickable" @click="this.$root.gotoRoute({ path: '/settings/about'})"><div class="actionIcon"><font-awesome-icon :icon="['fas', 'info-circle']" /></div><div>About</div></li>


        </ul>

        <button class="logOut clickable btn btn-danger" @click.prevent="logOut"><span class=""><font-awesome-icon class="logoutIcon" :icon="['fas', 'power-off']" /></span><span>Log Out</span></button>

      </div>
    </div>


  </div>
</template>

<script>

import dingDown from '@/assets/dingdown.mp3'

export default {
  name: 'Settings',
  components: {

  },
  data(){
    return{
      userInfo:{},
      isAdmin:'0',
    }
  },
  methods:{
    logOut() {
      this.$logoutSound.play();

      this.$store.dispatch('auth/logout');
      this.$router.push('/login');
    },

    goToMyBAs:function(){
      this.$root.gotoRoute({ path: '/settings/bas/'})
    },
    gotoMyProfile:function(){
      this.$root.gotoRoute({ path: '/settings/family/user', query: { userid: 'me'}})
    },
  },
  created() {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.userInfo = userInfo;
    this.isAdmin = userInfo && userInfo.FAMILY_ADMIN ? userInfo.FAMILY_ADMIN : '0';

  },
  mounted() {
    document.title = "Settings";
    //console.log(JSON.stringify(this.userInfo));

  }
}
</script>

<style scoped>

#settingsContainer{
  display:flex;
  justify-content: center;
  font-size:14px;
}

.settings li{
  display:flex;
  justify-content: left;
  align-items: center;
  margin:5px;
  text-decoration: none;
  color:#000;
}

.settings li{
  padding:12px;
}

.settings li:hover{
  background-color: #ebf0f1;
}


.settings li .actionIcon{
  width:20px;
  margin-right:20px;
}



.actionIcon{
  color:#0dcaf0;
}

.logoutIcon{
  margin-right:10px;
}
.logOut{
  display: block;
  font-size:12px;
  margin:auto;
  width:100px;
}


</style>